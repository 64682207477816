import { ModalSkeleton } from "../Shared";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices";
import { formatDistanceToNow } from "date-fns";
import { useEffect, useState } from "react";
import classNames from "classnames";

const NoticeModal = ({ openStatus, timeLeft, closeFunc }) => {
  const { profile } = useSelector(selectUser);

  // const [isCancel, setIsCancel] = useState(false)
  const [countTime, setCountTime] = useState(20)
   useEffect(() => {
     if (countTime > 0  && openStatus) {
       const timer = setInterval(() => {
         setCountTime((prev) => prev - 1);
       }, 1000);
       return () => clearInterval(timer);
     } 
     
   }, [countTime,  openStatus]);
  

  return (
    <ModalSkeleton
      openStatus={openStatus}
      closeFunc={countTime<1?closeFunc:()=>{}}
      dialogPanelStyles="max-w-[1000px] max-h-[500px] overflow-y-scroll"
    >
      <p className="animate-bounce text-center text-[25px] ">
        Demo video on new admin platform
      </p>
      <p className="font-bold text-red-500 text-center animate-pulse">
        {`Deactivated ${formatDistanceToNow(new Date(timeLeft), {
          addSuffix: true,
          includeSeconds: true,
        })}`}
      </p>
      <p className="mb-2">{`Hi ${profile?.firstName},`}</p>
      <p className="mb-2">
        This is to notify you that we would be migrating your cinema from the
        admin application{" "}
        <span className="text-blue-500">admin.reachcinema.io</span> to our newly
        designed office application{" "}
        <span>
          <a
            className="underline text-blue-500"
            href="https://office.reachcinema.io/"
            target="_blank"
            rel="noreferrer noopener"
          >
            office.reachcinema.io
          </a>
        </span>
        {". "}
        We have made lots of improvements on office to serve you better and we
        would be rolling out more updates periodically Your cinema's access to
        the admin application would be deactivated{" "}
        <span className="font-bold text-red-500 text-center animate-pulse">{`${formatDistanceToNow(
          new Date(timeLeft),
          {
            addSuffix: true,
            includeSeconds: true,
          }
        )}`}</span>
        {", "}
        so please ensure you login to office to start exploring it. For added
        security you would be required to use the{" "}
        <span className="font-bold">forgot password</span> feature to reset your
        password. We have also worked on a short overview video highlighting
        some key updates.
      </p>

      <p className="mb-2">
        {/* <span>
          {" "}
          Kindly watch the demo video attached to see how this new process
          works.{" "}
        </span> */}
        <span>
          If you have any further questions or need more clarification, please
          send an email to{" "}
        </span>
        <a
          href="mailto:support@reachcinema.io"
          className="underline text-blue-500"
        >
          support@reachcinema.io
        </a>{" "}
        .
      </p>
      <p className="mb-2">Kind Regards.</p>

      <div className="flex items-center justify-center mt-4">
        <button
          type="button"
          disabled={countTime>0}
          className={classNames(
            `relative min-w-[150px] flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500`,
            { "bg-gray-400": countTime>0 }
          )}
          onClick={closeFunc}
        >
          <span>{countTime<1 ? "Close" : `close in ${countTime}`}</span>
        </button>
      </div>
    </ModalSkeleton>
  );
};
export { NoticeModal };
