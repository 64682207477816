import React, { useEffect, useCallback, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";

import PhoneInput from "react-phone-number-input";

import UpdatePwdModal from "./UpdatePwdModal";

import { FETCH_STATUS, staffInit, staffSchema } from "../../utils";
import { FormError, InlineErr, CancelSaveToolbar, Input } from "../UI";
import { useLoggedInuser } from "../../hooks";
import { getRoles, selectRoles } from "../../redux/slices";

const wrapperClass = "grid-cols-[40%_minmax(0,1fr)] gap-4";

const StaffForm = ({
  onReset,
  status,
  error,
  onSave,
  isEdit,
  toggleStatus,
  info,
}) => {
  const { profile, userErr } = useLoggedInuser();

  const [showPwdModal, setShowPwdModal] = useState(false);

  const { roles, rolesStatus, rolesErr } = useSelector(selectRoles);

  const dispatch = useDispatch();

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    resolver: yupResolver(staffSchema),
    defaultValues: staffInit,
  });

  const onSubmit = async (values) => {
    onSave({ ...values, cinemaId: profile.cinemaId });
  };

  const loadRoles = useCallback(
    (force) => {
      dispatch(getRoles(force));
    },
    [dispatch]
  );

  useEffect(() => {
    if (rolesStatus === FETCH_STATUS.IDLE) {
      dispatch(getRoles());
    }
  }, [dispatch, rolesStatus]);

  useEffect(() => {
    if (isEdit) {
      reset({
        ...staffInit,
        ...info,
      });
    }
  }, [info, reset, isEdit, rolesStatus]);

  return (
    <>
      <UpdatePwdModal
        name={`${info?.firstName} ${info?.lastName}`}
        id={info?.id}
        handleClose={() => setShowPwdModal(false)}
        show={showPwdModal}
      />

      <form
        autoComplete="off"
        className="relative pb-32"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormError
          className="absolute top-0 z-20 transform left-2/4 -translate-x-2/4"
          err={error}
          onDismiss={onReset}
        />

        <FormError
          className="absolute top-0 z-20 transform left-2/4 -translate-x-2/4"
          err={userErr}
        />

        <FormError
          className="absolute top-0 z-20 transform left-2/4 -translate-x-2/4"
          err={rolesErr}
          onDismiss={loadRoles}
        />

        <CancelSaveToolbar backPath={"/dashboard/users"} status={status}>
          {isEdit ? (
            <>
              <button
                className={`p-2 rounded min-w-[92px] text-white ${
                  info?.isActive ? "bg-red-400 " : "bg-green-500"
                } `}
                onClick={toggleStatus}
                type="button"
              >
                {" "}
                {info?.isActive ? "Disable" : "Enable"}
              </button>

              <button
                className={`p-2 rounded min-w-[92px] text-white bg-app-blue `}
                onClick={() => setShowPwdModal(true)}
                type="button"
              >
                Update Password
              </button>
            </>
          ) : null}
        </CancelSaveToolbar>

        <section className="relative w-full px-3 py-8 bg-app-purple-2">
          <h3 className="text-lg font-medium">Staff Details</h3>
          <section className="gap-3 md:grid md:grid-cols-2 ">
            <div className="space-y-5 mt-11">
              <Input
                inputClass="!bg-transparent"
                readOnly
                disabled
                label="Cinema name:"
                value={profile?.cinema}
                wrapperClass={wrapperClass}
              />

              <Input
                label="First Name:"
                id="firstName"
                wrapperClass={wrapperClass}
                errorMsg={errors?.firstName?.message}
                {...register("firstName")}
                autoComplete="off"
              />

              <Input
                label="Last Name:"
                id="lastName"
                wrapperClass={wrapperClass}
                errorMsg={errors?.lastName?.message}
                autoComplete="off"
                {...register("lastName")}
              />

              <Controller
                control={control}
                name="phoneNumber"
                render={({ field: { value, onChange } }) => (
                  <div
                    className={`grid items-center gap-10 grid-cols-[12%_minmax(0,1fr)] ${wrapperClass}`}
                  >
                    <label className={`text-right `} htmlFor="phoneNumber">
                      Phone Number:
                    </label>

                    <div className="relative">
                      <PhoneInput
                        defaultCountry="NG"
                        value={value}
                        international
                        countryCallingCodeEditable={false}
                        onChange={onChange}
                      />
                      <InlineErr err={errors?.phoneNumber?.message} />
                    </div>
                  </div>
                )}
              />
            </div>

            <div className="space-y-5 mt-11">
              <Input
                label="Email:"
                id="email"
                wrapperClass={wrapperClass}
                errorMsg={errors?.email?.message}
                {...register("email")}
              />

              <Input
                label="Username:"
                id="userName"
                wrapperClass={wrapperClass}
                errorMsg={errors?.userName?.message}
                {...register("userName")}
                autoComplete="new-password"
              />

              {isEdit ? null : (
                <Input
                  label="Password:"
                  id="password"
                  autoComplete="new-password"
                  wrapperClass={wrapperClass}
                  type="password"
                  {...register("password")}
                  isPwd
                />
              )}

              <div
                className={`grid items-center gap-10 grid-cols-[12%_minmax(0,1fr)] ${wrapperClass}`}
              >
                <label className={`text-right `} htmlFor={"genderId"}>
                  Role Name:
                </label>

                <div className="relative">
                  <select
                    className={`w-full p-2 rounded bg-app-purple-10 `}
                    id={"roleName"}
                    name={"roleName"}
                    {...register("roleName")}
                  >
                    <option value="">Select...</option>
                    {roles.map((r) => (
                      <option key={r} value={r}>
                        {r}
                      </option>
                    ))}
                  </select>
                  <InlineErr err={errors?.roleName?.message} />
                </div>
              </div>

              <div>
                <InlineErr
                  className="!relative block my-2"
                  err={errors?.password?.message}
                />
              </div>
            </div>
          </section>
        </section>
      </form>
    </>
  );
};

export { StaffForm };
